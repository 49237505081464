import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from "react-router";
import './Card.css';

const Card = ({ cardsData }) => {

  const navigation = useNavigate();
  return (
    <div className="cards-container">
      {cardsData.map((data, index) => (
        <AnimatedCard key={index} data={data} index={index} />
      ))}
    </div>
  );
};

const AnimatedCard = ({ data, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [controls]);

  const variant = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delay: index * 0.2, // Staggered delay based on index
      },
    },
  };

  return (
    <>
      <motion.div
        ref={ref}
        className={`card ${isExpanded ? 'expanded' : ''}`}
        style={{
          backgroundImage: `url(${data.image})`,
        }}
        initial="hidden"
        animate={controls}
        variants={variant}
        whileHover={{ y: -10 }} // Keeps the translateY hover effect for the entire card
      >
        <div className="card-content">
          <h2 className="card-title">{data.title}</h2>
          <p className="card-body">{data.content}</p>
          {isExpanded && (
            <div className="expanded-content">
              {/* You can add more content here */}
            </div>
          )}
          {/* <button className="button" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Show Less' : 'Learn More'}
          </button> */}
        </div>
      </motion.div>
    </>
  );
};

export default Card;
