import React, { useState, useEffect, useContext, useRef } from "react";
import "./Contact.css";
import NavContext from "./Context/NavContext";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router";

function Contact() {
    const navigation = useNavigate();
    const navContext = useContext(NavContext);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [number, setNumber] = useState("");
    const [query, setQuery] = useState("");
    const logoRef = useRef(null);
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        company: "",
        number: "",
        query: ""
    });

    const [loading, setLoading] = useState(false); // State to manage form submission status

    useEffect(()=>{
        if(logoRef)
        {
            logoRef.current.scrollIntoView({behvaior:"smooth"});
        }
    })

    useEffect(() => {
        navContext.update_state("contact", document.getElementById("contact-container"));
    }, [navContext]);

    const handle_submit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        if (loading) return; // Prevent further execution if already loading
        setLoading(true); // Set loading to true

        const newErrors = {
            name: "",
            email: "",
            company: "",
            number: "",
            query: ""
        };

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.trim())) newErrors.email = "Please enter a valid email address.";
        if (company.trim() === "") newErrors.company = "Company name is required.";
        if (name.trim() === "") newErrors.name = "Your name is required.";
        if (number.trim() === "" || !/^[\d+\-()\s]+$/.test(number.trim())) newErrors.number = "A valid phone number is required.";
        if (query.trim() === "") newErrors.query = "Query or message is required.";

        if (Object.values(newErrors).some(error => error !== "")) {
            setErrors(newErrors);
            alert(Object.values(newErrors).filter(error => error !== "").join(" "));
            setLoading(false); // Reset loading state
            console.log(errors);
            return; // Exit the function to prevent further execution
        }

        // Email sending options
        const emailOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer sk_e82dc80705a378f18d1cd7ed3d1e82a01a5131571c71cf51`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                to: 'info@scriptix.tech',
                subject: company,
                body: `
                    <h3>Contact Form Submission</h3>
                    <p><strong>Name:</strong> ${name}</p>
                    <p><strong>Email:</strong> ${email}</p>
                    <p><strong>Company:</strong> ${company}</p>
                    <p><strong>Phone Number:</strong> ${number}</p>
                    <p><strong>Message:</strong></p>
                    <p>${query}</p>
                `,
                subscribed: true,
                name: name
            })
        };

        try {
            // Send email
            await fetch('https://api.useplunk.com/v1/send', emailOptions);

            alert("Your message has been sent successfully!");

            // Clear form fields after successful submission
            setName("");
            setEmail("");
            setCompany("");
            setNumber("");
            setQuery("");
            setErrors({
                name: "",
                email: "",
                company: "",
                number: "",
                query: ""
            });
        } catch (err) {
            console.error(err);
            alert("There was an error sending your message. Please try again later.");
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    // Function to handle number input only
    const handleNumberChange = (e) => {
        const { value } = e.target;
        // Allow only numbers, +, -, (, ), and space
        const filteredValue = value.replace(/[^0-9+\-()\s]/g, "");
        if (value !== filteredValue) {
            alert("Only numbers, +, -, (, ), and spaces are allowed in the phone number.");
        }
        setNumber(filteredValue);
    };

    return (
        <>
        <div id="contact-container">
            <img ref={logoRef} onClick={()=>{navigation("/")}} src = {logo} alt = "scriptix-logo"/>
            <p>Reach out to Scriptix to explore how our premium software solutions can transform and elevate your business. Our team is dedicated to providing personalized support and leveraging innovative technologies tailored to meet your unique needs. We're here to help you achieve your goals and drive success.</p>
            <input onChange={(e)=>{setName(e.target.value)}} type = "text" placeholder="Name"/>
            <input onChange={(e)=>{setEmail(e.target.value)}}  type = "email" placeholder="Email"/>
            <input onChange={(e)=>{setCompany(e.target.value)}}  type = "text" placeholder="Company Name"/>
            <input onChange={(e)=>{handleNumberChange(e)}}  type = "text" placeholder="Contact Number"/>
            <textarea onChange={(e)=>{setQuery(e.target.value)}}  placeholder="Project Description . . ."/>
            <button onClick={(e)=>{handle_submit(e)}}>Submit</button>
        </div>
        </>
    );
}

export default Contact;
