import React from "react";
import Home from "./Home";
import Services from "./Services";
import Section from "./Section";
import Footer from "./Footer";
import ScrollUpButton from "./ScrollUpButton"; // Import the ScrollUpButton

import aboutUsAnimation from "../assets/about_us_animation.mp4";
import workflowAnimation from "../assets/workflow_animation.mp4";

const Scriptix = () => {
    const aboutContent = "Scriptix is a forward-thinking software development company committed to delivering innovative and high-quality digital solutions. Our talented team specializes in Application Development, DevOps Solutions, Game Development, and Maintenance & Documentation. We take a client-focused approach, working closely with you to ensure each project is tailored to meet your unique needs and exceed your expectations. With a passion for technology and a dedication to excellence, Scriptix transforms your ideas into reality, helping you achieve your business goals with cutting-edge solutions. Partner with Scriptix and experience the difference of working with a team that values your success as much as you do.";
    const workflowContent = "At Scriptix, our workflow is designed to ensure efficiency, quality, and client satisfaction at every stage of the project. We start with a comprehensive discovery phase, where we gather requirements and understand your goals. Our planning phase involves detailed project roadmaps and timelines. During development, our agile methodology ensures flexibility and continuous feedback, allowing for iterative improvements. Rigorous testing is conducted to guarantee flawless performance and user experience. Finally, we deploy the solution and provide ongoing maintenance and support, ensuring your software remains up-to-date and efficient. Throughout the process, we maintain transparent communication, keeping you informed and involved every step of the way.";

    return (
        <div style={{ position: "relative" }}>
            <Home />
            <Services />
            <Section title="about" titleAnimation={aboutUsAnimation} content={aboutContent} />
            <Section title="workflow" titleAnimation={workflowAnimation} content={workflowContent} />
            <Footer />
            <ScrollUpButton />
        </div>
    );
};

export default Scriptix;
