import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import trian from "../assets/home_tri.png";
import WaterDropGrid from "./WaterDropGrid";
import logo from "../assets/logo.png";
import { useContext } from "react";
import NavContext from "./Context/NavContext";
import { TypeAnimation } from 'react-type-animation';
import arrow from "../assets/arrow.png";
import { useNavigate } from "react-router";
import "./Home.css";
import AnimatedSection from "./AnimatedSection";
import {Reveal} from "./Reveal"

const Home = () => {
    const navContext = useContext(NavContext);
    const [showSecondAnimation, setShowSecondAnimation] = useState(false);
    const firstAnimationRef = useRef(null);
    const navigation = useNavigate();

    const styles = {
        mainDiv: {
            backgroundColor: "black",
            paddingTop: "0vw",
            color: "white",
            height: "70vh",
            display: 'flex',
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            position: 'relative',
            marginTop:"0%"
        },
        secondDiv: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
        thirdDiv: {
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flex: 2,
            position: 'relative',
        },
        textContent: {
            position: 'relative',
            zIndex: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        buttonsDiv: {
            display: "flex",
            zIndex: 5,
            marginTop: '20px',
            marginLeft:"15%"

        },
        gridDiv: {
            paddingRight: "3%",
        }
    };

    const [gridSize, setGridSize] = useState({ width: 20, height: 20 });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 450) {
                setGridSize({ width: 10, height: 10 });
            } else if (window.innerWidth <= 1024) {
                setGridSize({ width: 15, height: 15 });
            } else if (window.innerWidth >= 2560) {
                setGridSize({ width: 30, height: 30 });
            } else {
                setGridSize({ width: 20, height: 20 });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set the initial size

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        navContext.update_state("home", document.getElementById("home-scroll"));
    }, [navContext]);

    const CURSOR_CLASS_NAME = 'type main-content';// HTMLSpanElement because 'span' is the default wrapper element of the <TypeAnimation/> component

 

    return (
        <>
            <Navbar />
            <div id="home-scroll" className="mainDiv" style={styles.mainDiv}>
                <img id="logo" src={logo} alt="logo" />
                <div style={styles.secondDiv}>
                    <img src={trian} alt="tri-icon" style={styles.trianStyle} className="trianStyle" />
                    <div style={styles.thirdDiv}>
                        <div style={styles.textContent} className="main-content-button">
                            <div className="textAnimationDiv">
                            <TypeAnimation
                                ref={firstAnimationRef}
                                sequence={[
                                    '{ Innovating Tomorrow with Code Today',
                                    (el) => {
                                        el.classList.remove('type')
                                        setShowSecondAnimation(true);
                                    }
                                ]}
                                speed={60}
                                style={{ fontSize: '3em', fontWeight: 'bold', textAlign : "left" }}
                                wrapper="div"
                                cursor={false} // Initially show cursor
                                repeat={0}
                                className={CURSOR_CLASS_NAME}
                            />
                            {showSecondAnimation && (
                                <TypeAnimation
                                    sequence={[
                                        'Shaping Digital Futures',
                                        1000,
                                        'Shaping Digital Careers',
                                        1000,
                                        'Shaping Digital Solutions',
                                        1000,
                                        'Shaping Digital Industry',
                                        1000

                                    ]}
                                    speed={60}
                                    style={{ fontSize: '3em', fontWeight: 'bold', color: '#00D8E3', textAlign:"left" }}
                                    wrapper="div"
                                    className="type1 main-content"
                                    cursor={false}
                                    repeat={Infinity} // Cursor for the second animation
                                />
                            )}

                            </div>
                            <Reveal>
                            <div onClick={()=>{navigation("/contact")}} style={styles.buttonsDiv} className="buttonsDiv">
                                    <p>Let's Build Together</p>
                                    <img src = {arrow} alt = "arrow"/>
                            </div>
                            </Reveal>
                        </div>
                        <Reveal>
                        <div style={styles.gridDiv} className="gridDiv">
                            
                            <WaterDropGrid width={gridSize.width} height={gridSize.height} />
                            
                        </div>
                        </Reveal>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default Home;
