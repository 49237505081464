import NavContext from "./NavContext";
import { useState } from "react";

function NavState(props)
{
    const state = {
        home : "",
        services: "",
        aboutus: "",
        workflow : "",
        contact : ""
    };

    const [state_, setState] = useState(state);

    const update_state = (key, value)=>{
        var tempState = state_;
        tempState[key] = value;
        setState(tempState);
    };

    return(
        <NavContext.Provider value={{state_, update_state}}>
            {props.children}
        </NavContext.Provider>
    )
};

export default NavState;