import React, {useEffect} from "react";
import { useContext } from "react";
import NavContext from "./Context/NavContext";
import "./Services.css";
import Card from "./Card";
import webdev from "../assets/webdev.png";
import ai from "../assets/ai.png";
import doc from "../assets/doc.png";
import devops from "../assets/devops.png";
import {motion} from "framer-motion"

const cardsData = [
    {
      title: "Web & App developement",
      content: "At Scriptix, we specialize in creating custom software solutions tailored to meet your business needs ensuring seamless integration.",
      image : webdev
    },
    {
      title: "Generative AI",
      content: "At Scriptix, we harness the power of artificial intelligence to deliver innovative AI solutions tailored to your business needs.",
      image: ai, // Replace with your image URL
    },
    {
      title: "Maintainance & Documentation",
      content: "We provide comprehensive maintenance and documentation services to keep your software running smoothly and up-to-date. ",
      image: doc, // Replace with your image URL
    },
    {
      title: "Dev-Ops",
      content: "Our DevOps solutions streamline your software development lifecycle by integrating development and operations.",
      image: devops, // Replace with your image URL
    },
  ];


const Services = () => {
    const styles = {
        mainDiv: {
            backgroundColor: "",
            marginTop:"5%"
        },
    };

    const navContext = useContext(NavContext);
    useEffect(()=>{
        navContext.update_state("services", document.getElementById("service-heading"));
    }, [navContext]);
    
    return (
        <>
            <h1 id="service-heading"><span>Services</span> We Provide</h1>
            <div id="service-scroll" style={styles.mainDiv}>
                <Card numOfCards={4} cardsData={cardsData} />
                
            </div>
        </>
    );
};

export default Services;
