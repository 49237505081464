import React, {useEffect} from "react";
import "./Services.css";
import "./Section.css";
import { useContext } from "react";
import NavContext from "./Context/NavContext";
import {motion} from "framer-motion"

const Section = ({ title, titleAnimation, content }) => {
    const styles = {
        mainDiv: {
            backgroundColor: "",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            paddingTop: "10vw"
        },
    };

    const navContext = useContext(NavContext);

    useEffect(()=>{
        if(title === "about")
        {
            navContext.update_state("aboutus", document.getElementById("about-scroll"));
        }
        else{
            navContext.update_state("workflow", document.getElementById("workflow-scroll"));
        }

    },[navContext, title]);

    return (
        <div id = {title === "about" ? "about-scroll" : "workflow-scroll"}>
            <div style={styles.mainDiv} className="mainDivCSS">
                {title === "about" ? (
                    <motion.div  initial={{x:200}}
                    whileInView={{ x:0 }} transition={{ease:"easeInOut", duration:0.2}}><h1 className="mainHeading"><span>About</span> Us</h1></motion.div>
                ) : (
                    <motion.div  initial={{ x:200}}
                    whileInView={{ x:0 }} transition={{ease:"linear", duration:0.2}}><h1 className="mainHeading">Our <span>Workflow</span></h1></motion.div>
                )}
                <div className="mainContent">
                <div id = "video-container"
                        dangerouslySetInnerHTML={{
                                __html: `<video id="why-video" width="100%" height="100%" autoplay loop muted playsinline>
                        <source src=${titleAnimation} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>`,
                            }}
                            />
                    <motion.p initial={{x:200}}
                    whileInView={{ x:0 }} transition={{ease:"linear"}}  id="mp"><p>{content}</p></motion.p>
                </div>
            </div>
        </div>
    );
}

export default Section;
