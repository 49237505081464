import React, { useState } from "react";
import logo from "../assets/logo.png";
import "./Navbar.css"; // Import the CSS file
import { useContext } from "react";
import NavContext from "./Context/NavContext";
import { useNavigate } from "react-router";
import { Reveal } from "./Reveal";


const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigation = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const navContext = useContext(NavContext);

    return (
        <div className="mainDivNav">
            <img src={logo} alt="logo" className="logo" />
            <button className="menuButton" onClick={toggleMenu}>
                ☰
            </button>
            <ul className={`navLinks ${isMenuOpen ? "open" : ""}`}>
                <button className="closeButton" onClick={toggleMenu}>
                    &times;
                </button>
                <Reveal>
                <li onClick={()=>{console.log(navContext.state_); navContext.state_["home"].scrollIntoView({behavior : "smooth"})}}>Home</li>
                </Reveal>
                <Reveal>
                <li onClick={()=>{navContext.state_["services"].scrollIntoView({behavior : "smooth"})}}>Services</li>
                </Reveal>
                <Reveal>
                <li onClick={()=>{navContext.state_["aboutus"].scrollIntoView({behavior : "smooth"})}}>About Us</li>
                </Reveal>
                <Reveal>
                <li onClick={()=>{navContext.state_["workflow"].scrollIntoView({behavior : "smooth"})}}>Workflow</li>
                </Reveal>
                <Reveal>
                <li onClick={()=>{navigation("/contact")}}>Contact</li>
                </Reveal>
            </ul>
            {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
        </div>
    );
};

export default Navbar;
