// components/ScrollUpButton.js
import React from 'react';
import './ScrollUpButton.css'; // You will create this CSS file next

const ScrollUpButton = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <button className="scroll-up-button" onClick={scrollToTop}>
            ↑
        </button>
    );
};

export default ScrollUpButton;
