import React from "react";
import Scriptix from "./components/Scriptix";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Contact from "./components/Contact";

//Fixing Issues

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path = "/" element = {<Scriptix/>}/>
      <Route path = "/contact" element = {<Contact/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
