import React, {useEffect} from "react";
import anime from "animejs";
import "./WaterDropGrid.css";

const WaterDropGrid = ({ width, height }) => {
    const gridStyles = {
        display: 'grid',
        placeContent: 'center',
        padding: '3rem 2rem'
    };

    return (
        <div style={gridStyles}>
            <DotGrid width={width} height={height} />
        </div>
    );
};

const DotGrid = ({ width, height }) => {
    const handleDotHover = (e) => {
        anime({
            targets: ".dot-point",
            scale: [
                { value: 1.35, easing: "easeOutSine", duration: 250 },
                { value: 1, easing: "easeInOutQuad", duration: 500 },
            ],
            translateY: [
                { value: -15, easing: "easeOutSine", duration: 250 },
                { value: 0, easing: "easeInOutQuad", duration: 500 },
            ],
            opacity: [
                { value: 1, easing: "easeOutSine", duration: 250 },
                { value: 1, easing: "easeInOutQuad", duration: 500 },
            ],
            delay: anime.stagger(100, {
                grid: [width, height],
                from: e.target.dataset.index,
            }),
        });
    };

    const handleDotHoverIntial = () => {
        anime({
            targets: ".dot-point",
            scale: [
                { value: 1.35, easing: "easeOutSine", duration: 250 },
                { value: 1, easing: "easeInOutQuad", duration: 500 },
            ],
            translateY: [
                { value: -15, easing: "easeOutSine", duration: 250 },
                { value: 0, easing: "easeInOutQuad", duration: 500 },
            ],
            opacity: [
                { value: 1, easing: "easeOutSine", duration: 250 },
                { value: 1, easing: "easeInOutQuad", duration: 500 },
            ],
            delay: anime.stagger(100, {
                grid: [width, height],
                from: 0,
            }),
        });
    };

    const dotContainerStyles = {
        display: 'grid',
        gridTemplateColumns: `repeat(${width}, 1fr)`,
        width: 'fit-content',
    };

    const dotWrapperStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        padding: '8px',
        transition: 'background-color 0.2s',
    };

    var dotPointStyles = {
        height: '8px',
        width: '8px',
        borderRadius: '25%',
        background: '#00D8E3',
        transform : 'rotate(45deg)',
        opacity: '0.8',
        transition: 'background-color 0.2s',
    };

    var dotPointStylesArray = []

    var initialSize = 5;
    for(var i = 0; i<width; i++)
    {
        dotPointStylesArray.push({
            height: String(initialSize - 1) + "px",
            width: String(initialSize - 1) + "px",
            borderRadius: '25%',
            background: '#00D8E3',
            transform : 'rotate(45deg)',
            opacity: '0.8',
            transition: 'background-color 0.2s',
        });
        if(initialSize <= 10)
        {
            initialSize = initialSize + 0.3;
        }

    }
// eslint-disable-next-line
    useEffect(()=>{
        // eslint-disable-next-line
        handleDotHoverIntial();
        // eslint-disable-next-line
    },[]);

    const dots = [];
    let index = 0;

    for (let i = 0; i < width; i++) {
        for (let j = 0; j < height; j++) {
            dots.push(
                <div
                    key={`${i}-${j}`}
                    data-index={index}
                    style={dotWrapperStyles}
                    onMouseEnter={handleDotHover}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#00D8E3'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                >
                    <div
                        className="dot-point"
                        data-index={index}
                        style={dotPointStylesArray[i]}
                    />
                </div>
            );
            index++;
        }
        dotPointStyles.height = String(initialSize - 1) + "px";
        dotPointStyles.width = String(initialSize - 1) + "px";
    }

    return (
        <div style={dotContainerStyles}>
            {dots}
        </div>
    );
};

export default WaterDropGrid;
