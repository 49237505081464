import React from "react";
import "./Footer.css";
import scriptix from "../assets/logo.png";
import { useContext } from "react";
import NavContext from "./Context/NavContext";
function Footer()
{
    const navContext = useContext(NavContext);

    return(
        <>
        <div id="footer">
            <div id="vortexLabs">
                <img src = {scriptix} alt = "logo"/>
            </div>
            <p>
            Connect with Scriptix to discover how our premium software solutions can elevate and transform your business. Our dedicated team offers personalized support and leverages cutting-edge technologies to meet your specific needs. We're committed to helping you achieve your goals and drive success.
            </p>

            <div id="footer-info">
                <div id="contact">
                    <p>Contact</p>
                    <p>info@scriptix.tech</p>
                    <p>+92-3486607708</p>
                    <p>+92-3004564278</p>
                    <p>80 A Tip Housing Society Lahore, Punjab</p>
                </div>

                <div id="quick-links">
                    <p onClick={()=>{navContext.state_["home"].scrollIntoView({behavior : "smooth"})}}>Home</p>
                    <p onClick={()=>{navContext.state_["services"].scrollIntoView({behavior : "smooth"})}}>Services</p>
                    <p onClick={()=>{navContext.state_["workflow"].scrollIntoView({behavior : "smooth"})}}>Workflow</p>
                    <p onClick={()=>{navContext.state_["aboutus"].scrollIntoView({behavior : "smooth"})}}>About Us</p>
                </div>
            </div>


        </div>
        </>
    );
};

export default Footer;